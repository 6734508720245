import React from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'

// Images
import Close from 'img/close.inline.svg'

// Components
import useMedia from 'use-media'
import ButtonPrimary from '../Buttons/ButtonPrimary'

const CloseWrapper = styled.button`
  top: 25px;
  right: 35px;
  & svg {
    width: 30px;
    height: 30px;

    & path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }

  &:hover {
    opacity: 0.6;
  }
`

// Interface
interface AnydeskProps {
  isOpen: boolean
  closeModal: Function
}

const Anydesk = ({ isOpen, closeModal }: AnydeskProps) => {
  const mobile = useMedia({ maxWidth: 767 })

  const customStyles = {
    overlay: {
      background: 'rgba(99, 109, 255, 0.5)',
    },
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#FFF',
      width: mobile ? '100%' : '60%',
      color: 'rgba(99, 109, 255, 1)',
      padding: '3rem',
      borderRadius: '50px',
    },
  }
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <CloseWrapper
        className="position-absolute"
        onClick={() => closeModal(true)}
      >
        <Close />
      </CloseWrapper>
      <h2 className="font-weight-bold">Remote Support</h2>
      <p>
        Om met u mee te kijken op de werkplek gebruiken we de software van
        AnyDesk. Om dit te kunnen gebruiken heeft u wel een stukje software op
        uw werkplek nodig. Die kunt u downloaden via de links hieronder. Neem
        contact op met onze servicedesk om te horen wat de benodigde stappen
        zijn.
      </p>
      <h2 className="font-weight-bold">AnyDesk</h2>
      <p>
        Er zijn twee verschillende downloads, één voor Windows-systemen en één
        voor Apple-systemen:
      </p>
      <div className="d-flex flex-column flex-md-row justify-content-center mt-5">
        <ButtonPrimary
          to="https://get.anydesk.com/HWxb0MHH/AnyDesk_GTSOnline_Windows.exe"
          className={`me-4 mb-4 mb-md-0 text-center ${mobile ? 'w-100' : ''}`}
        >
          Download AnyDesk voor Windows
        </ButtonPrimary>
        <ButtonPrimary
          to="https://download.anydesk.com/anydesk.dmg"
          className="text-center"
        >
          Download AnyDesk voor Apple
        </ButtonPrimary>
      </div>
    </ReactModal>
  )
}

export default Anydesk
